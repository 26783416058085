import React from 'react'

export const InspectBtn = ({ btnClass = "btn-primary", btnLabel = "Schedule an Inspection" }) => (
  <a
    href="https://ezo.bpginspections.com"
    target="_blank"
    rel="noreferrer"
  >
    <button className={`btn ${btnClass}`}>
      {btnLabel}
    </button>
  </a>
)

export const Btn = ({ link, btnType = "btn-primary", btnLabel = "Click Here", ...props }) => (
  <a href={link} {...props}>
    <button
      className={`btn transition-3d-hover ${btnType}`}
    >
      {btnLabel}
    </button>
  </a >
)
