import React from 'react'

/** @jsx jsx */
import { jsx } from "theme-ui"
import { Nav } from "../Nav"

export const Header = () => {
  return (
    <header id="header" className="pt-3">
      <div id="logoAndNav" className="container">
        <Nav />
      </div>
    </header>
  )
}
