import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "../components/layout"
import { Hero } from "../components/Hero"
import { TopList } from "../components/TopList"
import { InspectBtn } from '../components/btns';



const Structure = () => (
  <Layout>
    <Hero />
    <TopList />
    <div className="col-md-10 col-lg-7 mx-auto">
      <InspectBtn btnClass="btn-primary btn-block" btnLabel="Click Here To Schedule Your Inspection Now" />
    </div>
  </Layout >
)

export default Structure
