import React from 'react'
import { TopListItem } from "./TopListItem"
export const TopList = () => {
  return (
    <div className="container lightShadow">
      <div className="card text-center card mx-auto mb-7 border-top px-6">
        <h2 className="text-primary mt-4">
          Top 10 New Home Defects
      </h2>
        <p className="text-secondary">found on homes 1 year old or less</p>
        <p></p>
        <ol className="list-unstyled row text-left">
          <TopListItem item="Improper Grading" />
          <TopListItem item="Poor Installation of Flooring/Drywall" />
          <TopListItem item="Improper sink / cabinet/ countertop installation" />
          <TopListItem item="Attic Trusses not properly braced" />
          <TopListItem item="Floor Joists improperly cut." />
          <TopListItem item="Missing or improper window and door flashings" />
          <TopListItem item="Improper Dryer Vents" />
          <TopListItem item="Lack of proper fire stops" />
          <TopListItem item="Sidewalks/ patios sloped improperly" last />
          <TopListItem item="Exhaust fans not properly vented." last />
        </ol>
      </div>
    </div>
  )
}
