/** @jsx jsx */
import React from "react"
import { Global } from "@emotion/core"
import { css, Styled, jsx } from "theme-ui"
import SEO from "./seo"
import { Header } from "../components/Header"
import "../styles/main.scss"
// import Info from './Drawer/Info' // 🚧 fix info import
// import { Header } from "./Header"
type LayoutProps = { children?: React.ReactNode; className?: string }

const Layout = ({ children, className }: LayoutProps) => (
  <Styled.root data-testid="theme-root">
    <Global
      styles={css({
        "*": {
          boxSizing: `border-box`,
          "&:before": {
            boxSizing: `border-box`,
          },
          "&:after": {
            boxSizing: `border-box`,
          },
        },
      })}
    />
    <SEO />
    <Header />
    <main className={className} sx={{
      mb: 5
    }}>
      {children}
    </main>

  </Styled.root>
)

export default Layout
