import React from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import { InspectBtn, Btn } from '../btns'

export const Signup = () => {
  return (
    <div className="card shadow-lg">
      <div className="card-header bg-light text-center py-4 px-5 px-md-6">
        <h2 className="h4 mb-0">
          Two Easy Options To Schedule Your Inspection
        </h2>
      </div>

      <div className="card-body p-4 p-md-6">
        <div className="row d-flex flex-column jusitfy-content-center">
          <InspectBtn btnClass="btn-primary btn-block" />
          <div className="text-center mx-auto">
            <h3 className="my-3 text-secondary">Text Or Call:</h3>
          </div>
          <Btn
            link="tel:1-610-417-0722"
            btnType="btn-block btn-secondary"
            btnLabel="(610) 417-0722"
          />
        </div>
      </div>
    </div>
  )
}
