import React, { useState } from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'

import { InspectBtn } from '../btns'
import { Collapse, Navbar, NavbarToggler, Nav as ReactNav } from 'reactstrap'
import Logo from '../../assets/images/logo.png'

export const Nav = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  return (
    <div>
      <Navbar light expand="md">
        <a href="/">
          <img src={Logo} alt="Logo" sx={{ width: '250px' }} />
        </a>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <ReactNav className="ml-auto" navbar>
            <h3
              className="pr-3 pt-1"
              sx={{
                color: 'icon_orange',
                '&:hover': { color: 'icon_red' },
              }}
            >
              <a href="tel:1-610-417-0722">(610) 417-0722</a>
            </h3>
            <InspectBtn btnClass="btn-danger" />
          </ReactNav>
        </Collapse>
      </Navbar>
    </div>
  )
}
