import React from 'react'
/** @jsx jsx */
import { jsx } from "theme-ui"


import { Signup } from "../Signup"
import { dlBtn, Btn } from "../btns"
import seal from "../../assets/images/ASHI_certified.png"
import photoJournal from "../../assets/docs/Integrity_Inspections-new_home_photo_journal.pdf"

export const Hero = () => {
  return (
    <div className="container z-index-4 space-top-2 space-bottom-2">
      <div className="row justify-content-lg-between align-items-lg-center">
        <div className="hero-left col-md-10 col-lg-5 mb-9 mb-lg-7">
          <div className="mb-4 text-xs-center text-lg-left">
            <h1 className="display-4 mb-4">
              Schedule Your 11-Month Warranty Inspection
            </h1>
            <img className="img-fluid" src={seal} alt="American Society of Home Inspectors" />
          </div>
          <div className="space-top-2">
            <Btn
              link={photoJournal}
              btnLabel="Download New Home Defect Photo Journal"
              rel="noopener noreferrer"
              download
            />
          </div>
        </div>

        <div className="col-lg-6">
          <Signup />
        </div>
      </div>
    </div>

  )
}
